import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { NewCertificationCriterions, NewCertificationInfo, RequestsList, TeacherInfo } from './Components'
import { useFormik } from 'formik'
import { firstFormValidationSchema } from '../../assets/validation'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { sendRequest } from '../../actions/teacher'
import { getSpecializations, getRequests, removeRequestsList, removeRequestData, getLessons } from '../../actions/common'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { createNotification } from '../../components/Notifications/Notifications'
import Button from '../../components/Button'
import Confirmation from '../../components/Confirmation/Confirmation'


const firstFormInitialValues = {
  cert_year: '',
  simple_cert: false,
  position: '',
  category: '',
  cert_request_scan: [],
  cert_request_common: [],
  cert_request_award: [],
  current_position: '',
  cert_reason: '',
  edu_exp: null,
  position_exp: null,
  org_exp: null
}

export default function Teacher() {
  
  const [infoActiveMode, setInfoActiveMode] = useState(false)
  const [confirmation, setConfirmation] = useState(null)


  const { userInfo: {current_position, ...userInfo} } = useSelector(state => state.auth)
  const { loading } = useSelector(state => state.teacher)
  const { criterions, specializations, requestsList, lessons, loading: reqListLoading } = useSelector(state => state.common)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  useEffect(() => {
    dispatch(removeRequestData())
    !specializations && dispatch(getSpecializations())
    !requestsList && dispatch(getRequests())
    return () => {
      dispatch(removeRequestsList())
    }
  }, [])
  
  
  const firstForm = useFormik({
    initialValues: {...firstFormInitialValues, ...userInfo},
    enableReinitialize: true,
    validationSchema: firstFormValidationSchema,
    validateOnMount: true,
    onSubmit: values => submitFirstForm(values)
  })

  const submitFirstForm = (values) => {
    const {cert_request_common, cert_request_scan, simple_cert, cert_request_award} = values
    let errorText
    !cert_request_common?.length && (errorText = `Вы не прикрепили файл "Общие сведения о педагоге"`)
    !cert_request_scan?.length && (errorText = `Вы не прикрепили файл заявления`)
    simple_cert && !cert_request_award?.length && (errorText = `Для подачи заявления по упрощенной схеме необходимо прикрепить хотя бы 1 награду`)
    if (errorText) return createNotification('error', errorText)

    dispatch(sendRequest(values))
  }

 
  const resetFirstForm = () => {
    firstForm.handleReset()
    setInfoActiveMode(false)
  }
  
  const clearRequest = () => {
    dispatch(removeRequestData())
    resetFirstForm()
    dispatch(getRequests())
  }

  const requestClick = ({id}) => () => {
    if (infoActiveMode) return setConfirmation({saveMode: !!criterions, id});
    navigate(`/request/${id}`)
  }
  
  const sharedFilesClick = () => {
    navigate(`/shared_files`)
  }

  const acceptConfirmation = () => {
    clearRequest()
    setConfirmation(null)
  }

  const rejectConfirmation = () => {
    setConfirmation(null)
  }

  return (
    <div className={styles.container}>
      {confirmation &&
        <Confirmation
          title={`Подача заявление не завершена. ${confirmation.saveMode ? 'Сохранить в черновик' : 'Отменить подачу'} и выйти?`}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
      }
      <Row>
        <Col>
          <TeacherInfo/>
        </Col>
        <Col>
          <Button 
            type='submit'
            label={"Методическая копилка"}
            onClick={() => sharedFilesClick()}
            className={styles.storageButton}
          />
          <RequestsList
            requestClick={requestClick}
            requestsList={requestsList}
            loading={reqListLoading}
          />
        </Col>
      </Row>
      <NewCertificationInfo
        specializations={specializations}
        formData={firstForm}
        criterions={criterions}
        activeMode={infoActiveMode}
        setActiveMode={setInfoActiveMode}
        clearRequest={clearRequest}
        resetForm={resetFirstForm}
        loading={loading && !criterions}
      />
      {criterions && 
        (loading
          ? <Loader/>
          : <NewCertificationCriterions
              setInfoActiveMode={setInfoActiveMode}
              // resetFirstForm={resetFirstForm}
              criterions={criterions}
              clearRequest={clearRequest}
            />
        )
      }
    </div>
  )
}