import * as types from "../actions/types";
import { groupCriterions } from "../utils/criterions";



const initialState = {
    specializations: null,
    requestsList: null,
    requestParts: null,
    currentRequest: null,
    criterions: null,
    userInfo: null,
    lessons: null,
    topics: null,
    files: null,
    loading: false,
    awards: null,
    comission: {
        list: null,
        values: null
    }
};


export default function common (state = initialState, action) {
  switch (action.type) {
    case types.REMOVE_COMMON_REQUEST_DATA:
      return {
        ...state,
        criterions: null,
        currentRequest: null,
        requestParts: null,
        comission: initialState.comission
      };
    case types.SET_SPECIALIZATIONS:
      return {
        ...state,
        specializations: action.payload,
      };
    case types.SET_REQUESTS_LIST:
      return {
        ...state,
        requestsList: action.payload
      };
    case types.SET_REQUESTS_PARTS:
      return {
        ...state,
        requestParts: action.payload
      };
    case types.SET_CURRENT_REQUEST:
      return {
        ...state,
        currentRequest: action.payload
      };
    case types.SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload
      };
    case types.SET_LESSONS:
      return {
        ...state,
        lessons: action.payload
      };
    case types.SET_TOPICS:
      return {
        ...state,
        topics: action.payload
      };
    case types.SET_FILES_LIST:
      return {
        ...state,
        files: action.payload
      };
    case types.SET_COMMON_CRITERIONS:
      const criterions = groupCriterions(action.payload, action.chapters)
      return {
        ...state,
        criterions
      };
    case types.SET_COMMISSION_LIST:
      return {
        ...state,
        comission: {
          ...state.comission,
          list: action.payload
        }
      };
    case types.SET_COMMISSION_VALUES:
      return {
        ...state,
        comission: {
          ...state.comission,
          values: action.payload
        }
      };
    case types.SET_REQUEST_AWARDS:
      return {
        ...state,
        awards: action.payload
      };
    case types.SET_COMMON_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case types.SIGNED_OUT:
      return initialState
    default:
      return state;
  }
}