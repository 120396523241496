import jsPDF from "jspdf"
// import addFont from "../../../../fonts/fonts"
import 'jspdf-autotable'
import moment from "moment"
import addFont from "../../../../../assets/fonts/fonts"
import {htmlToText} from 'html-to-text'
import { MONTH_GENITIVE } from "../../../../../assets/const"

import mo from '../../../../../assets/images/pdfLogos/MO.png'
import lms from '../../../../../assets/images/pdfLogos/LMS.png'
import guk from '../../../../../assets/images/pdfLogos/GUK.png'
import { getInitials } from "../../../../../utils/smallHelpers"

const orientation = 'p'

export const getProtocol = async ({data, comission, userInfo}) => {

  // const { all_requests, failure_all, first_all, first_failure, first_success, high_all, high_failure, high_success, success_all } = data?.teachers_statistic || {}

  const {last_name, first_name, patronymic} = userInfo
  const createrName = `${last_name || ''} ${first_name || ''} ${patronymic || ''}`

  let lastY = 35

  const getY = (indent, newValue) => {
    let newY = newValue ? newValue + indent : lastY + indent
    if (newY > doc.internal.pageSize.height - 30) {
      doc.addPage('a4', orientation)
      newY = 35
    }
    lastY = newY
    return newY
  }

  const addLogos = () => {
    const pageCount = doc.internal.getNumberOfPages()
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      
      doc.addImage(guk, 'PNG', 10, 5, 20, 25)
      doc.addImage(mo, 'PNG', 95, 3, 20, 12)
      doc.addImage(lms, 'PNG', 190, 285, 15, 9)

      doc.setFontSize(9)
      doc.text('Министерство обороны Российской Федерации', 105, 20, 'center')
      doc.text('Управление военного образования Главного управления кадров', 105, 25, 'center')

      doc.setFontSize(8)
      doc.text(`Кто создал: ${createrName || ''}`, 10, 292)
      doc.text(`Дата: ${moment(comission?.date_to).format('DD.MM.YYYY')}`, 10, 295)
      doc.text(`Лист ${i} из ${pageCount}`, 100, 295, 'center')
      doc.text('Документ сформирован в системе управления обучением', 100, 290)

    }
  }

  const dateFormatted = (date) => moment(date).format(`DD ${MONTH_GENITIVE[moment(date).month()]} YYYY`)

  const comissionPeriod = `${dateFormatted(comission?.date_from)} - ${dateFormatted(comission?.date_to)}`

  addFont(jsPDF.API)
  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })

  doc.setFont('TimesNewRoman')
  doc.setFontSize(13)
  
  
  doc.text('Протокол № 2', 105, lastY, 'center')
  doc.text('заседания экспертной группы Аттестационной комиссии УВО ГУК', 105, getY(7), 'center')
  doc.text('Министерства обороны Российской Федерации по аттестации', 105, getY(7), 'center')
  doc.text('педагогических и руководящих работников организаций, осуществляющих', 105, getY(7), 'center')
  doc.text('образовательную деятельность и находящихся в ведении Министерства', 105, getY(7), 'center')
  doc.text('обороны Российской Федерации,', 105, getY(7), 'center')
  doc.text(`от ${dateFormatted(comission?.date_to)} года`, 105, getY(7), 'center')
  
  doc.setFontSize(12)
  doc.setFont(undefined, 'bold')
  
  doc.text(`Присутствовали:`, 23, getY(14))
  
  doc.setFont(undefined, 'normal')
  
  let text = 'Руководитель экспертной группы Н.А.Близнова – заведующий методическим кабинетом Московского суворовского военного ордена Почета училища.'
  let rows = doc.splitTextToSize(text, 180)
  doc.text(rows, 23, getY(14))

  doc.text(`Члены экспертной группы:`, 23, getY(14))

  data.experts.forEach(item => {
    const fio = getInitials(`${item.LastName || ''} ${item.FirstName || ''} ${item.Patronymic || ''}`)
    const text = `${fio} - ${item.doljnost || ''}`
    const rows = doc.splitTextToSize(text, 160)
    doc.text(rows, 33, getY(14))
  })
  
  doc.setFont(undefined, 'bold')
  doc.text(`Повестка дня:`, 105, getY(10), 'center')
  doc.setFont(undefined, 'normal')

  text = '1. Рассмотрение аттестационных материалов педагогических работников с целью установления первой квалификационной категории в январе  2025 года.'
  rows = doc.splitTextToSize(text, 180)
  doc.text(rows, 23, getY(10))

  text = '2. Рассмотрение аттестационных материалов педагогических работников с целью установления высшей квалификационной категории в январе 2025 года.'
  rows = doc.splitTextToSize(text, 180)
  doc.text(rows, 23, getY(14))

  doc.setFont(undefined, 'bold')
  doc.text(`Ход заседания:`, 105, getY(14), 'center')
  doc.setFont(undefined, 'normal')
  
  text = '1. Заслушали руководителя экспертной группы Н.А.Близнову по вопросу проведения аттестации педагогических работников организаций, осуществляющих образовательную деятельность и находящихся в ведении Министерства обороны Российской Федерации, с целью установления'
  rows = doc.splitTextToSize(text, 180)
  doc.text(rows, 23, getY(10))

  doc.setFont(undefined, 'bold')
  doc.text(`первой квалификационной категории.`, 23, lastY + 14)
  doc.setFont(undefined, 'normal')
  
  text = 'Количество представленных аттестационных материалов (портфолио профессиональной деятельности) для установления первой квалификационной категории – 34.'
  rows = doc.splitTextToSize(text, 180)
  doc.text(rows, 23, getY(24))

  doc.text('По результатам подтверждено – 34.', 23, getY(14))

  text = 'По результатам экспертизы представленных аттестационных материалов считать уровень квалификации следующих педагогических и руководящих работников соответствующим требованиям, предъявляемым к '
  rows = doc.splitTextToSize(text, 180)
  doc.text(rows, 23, getY(10))

  doc.setFont(undefined, 'bold')
  doc.text(`первой квалификационной категории:`, 82, lastY + 9.7)
  doc.setFont(undefined, 'normal')

  Object.keys(data.reasons_statistic_first_category).forEach((school, ind) => {
    doc.setFont(undefined, 'bold')
    doc.text(school, 105, getY(ind ? 10 : 25), 'center')
    doc.setFont(undefined, 'normal')

    data.reasons_statistic_first_category[school].forEach((item, ind) => {
      const fio = `${item.lastName || ''} ${item.firstName || ''} ${item.patronymic || ''}, ${item.position || ''}`
      doc.text(`${ind + 1}. ${fio}`, 23, getY(10))
    })
  })

  doc.setFont(undefined, 'bold')
  doc.text(`Решение экспертной группы:`, 105, getY(10), 'center')
  doc.setFont(undefined, 'normal')
  
  text = 'Рекомендовать установить первую квалификационную категорию вышеперечисленным педагогическим работникам организаций, осуществляющих образовательную деятельность и находящихся в ведении Министерства обороны Российской Федерации, по занимаемым должностям.'
  rows = doc.splitTextToSize(text, 180)
  doc.text(rows, 23, getY(10))

  doc.text('Результаты голосования:', 23, getY(26))
  doc.text('«за» – 9', 23, getY(5))
  doc.text('против» – 0', 23, getY(5))


  text = '2. Заслушали руководителя экспертной группы Н.А.Близнову по вопросу проведения аттестации педагогических работников организаций, осуществляющих образовательную деятельность и находящихся в ведении Министерства обороны Российской Федерации, с целью установления'
  rows = doc.splitTextToSize(text, 180)
  doc.text(rows, 23, getY(10))

  doc.setFont(undefined, 'bold')
  doc.text(`высшей квалификационной категории.`, 23, lastY + 14)
  doc.setFont(undefined, 'normal')
  
  text = 'Количество представленных аттестационных материалов (портфолио профессиональной деятельности) для установления первой квалификационной категории – 34.'
  rows = doc.splitTextToSize(text, 180)
  doc.text(rows, 23, getY(24))

  doc.text('По результатам подтверждено – 34.', 23, getY(14))

  text = 'По результатам экспертизы представленных аттестационных материалов считать уровень квалификации следующих педагогических и руководящих работников соответствующим требованиям, предъявляемым к '
  rows = doc.splitTextToSize(text, 180)
  doc.text(rows, 23, getY(10))

  doc.setFont(undefined, 'bold')
  doc.text(`высшей квалификационной категории:`, 82, lastY + 9.7)
  doc.setFont(undefined, 'normal')

  Object.keys(data.reasons_statistic_highest_category).forEach((school, ind) => {
    doc.setFont(undefined, 'bold')
    doc.text(school, 105, getY(ind ? 10 : 25), 'center')
    doc.setFont(undefined, 'normal')

    data.reasons_statistic_highest_category[school].forEach((item, ind) => {
      const fio = `${item.lastName || ''} ${item.firstName || ''} ${item.patronymic || ''}, ${item.position || ''}`
      doc.text(`${ind + 1}. ${fio}`, 23, getY(10))
    })
  })

  doc.setFont(undefined, 'bold')
  doc.text(`Решение экспертной группы:`, 105, getY(10), 'center')
  doc.setFont(undefined, 'normal')
  
  text = 'Рекомендовать установить первую квалификационную категорию вышеперечисленным педагогическим работникам организаций, осуществляющих образовательную деятельность и находящихся в ведении Министерства обороны Российской Федерации, по занимаемым должностям.'
  rows = doc.splitTextToSize(text, 180)
  doc.text(rows, 23, getY(10))

  doc.text('Результаты голосования:', 23, getY(26))
  doc.text('«за» – 9', 23, getY(5))
  doc.text('против» – 0', 23, getY(5))

  doc.text('Руководитель экспертной группы:', 23, getY(16))
  doc.text('Н.А. Близнова', 180, getY(8), 'right')
  
  doc.text('Члены экспертной группы:', 23, getY(16))
  
  data.experts.forEach(item => {
    const fio = getInitials(`${item.LastName || ''} ${item.FirstName || ''} ${item.Patronymic || ''}`)
    doc.text(fio, 180, getY(8), 'right')
  })

  addLogos()

  doc.addPage('a4', orientation)
  doc.deletePage(doc.getNumberOfPages())
  return doc
}